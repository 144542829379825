import React, { useState, useEffect } from "react"
import {
  JsonView,
  allExpanded,
  collapseAllNested,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite"
import { JSONTree } from "react-json-tree"
import "react-json-view-lite/dist/index.css"
import axios from "axios"
import { atomOneDark, CodeBlock, a11yDark } from "react-code-blocks"
import { ethers } from "ethers"
import "font-awesome/css/font-awesome.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { scroller } from "react-scroll"
import JsonTable from "./JsonTable"
import ChainSelector from "./ChainSelector"
import OmnNav from "./OmnNav"

//
import "./App.css"

const App = () => {
  const [searchData, setSearchData] = useState("")
  const [transactionStatus, setTransactionStatus] = useState("")
  const [sourceCode, setSourceCode] = useState("")
  const [trace, setTrace] = useState("")
  const [errorReason, setErrorReason] = useState("")
  const [decodedInput, setDecodedInput] = useState("")
  const [abi, setAbi] = useState("")
  const [searching, setSearching] = useState(false)
  const [customErrors, setCustomErrors] = useState(false)
  const [displayNav, setDisplayNav] = useState(false)
  const [chainId, setChainId] = useState(1)
  const [message, setMessage] = useState(null)
  const [eventTopics, setEventTopics] = useState("")
  const [functionSignatures, setFunctionSignatures] = useState("")
  const [contractBytecode, setContractBytecode] = useState("")
  const [contractBytecodeHash, setContractBytecodeHash] = useState("")
  const [searchType, setSearchType] = useState("")

  const chains = [
    { image: "./1.svg", text: "eth mainnet", chainId: "1" },
    { image: "./11155111.svg", text: "eth sepolia", chainId: "11155111" },
    { image: "./42161.svg", text: "arb mainnet", chainId: "42161" },
    { image: "./421614.svg", text: "arb sepolia", chainId: "421614" },
    { image: "./10.svg", text: "opt mainnet", chainId: "10" },
    { image: "./11155420.svg", text: "opt sepolia", chainId: "11155420" },
    { image: "./8453.svg", text: "base mainnet", chainId: "8453" },
    { image: "./84532.svg", text: "base sepolia", chainId: "84532" },
  ]

  const blockExplorerState = {
    1: "https://etherscan.io/",
    11155111: "https://sepolia.etherscan.io/",
    42161: "https://arbiscan.io/",
    421614: "https://sepolia.arbiscan.io/",
    10: "https://optimistic.etherscan.io/",
    11155420: "https://sepolia-optimism.etherscan.io/",
    8453: "https://basescan.org/",
    84532: "https://sepolia.basescan.org/",
  }

  const scannerLink = blockExplorerState[chainId] + searchType + searchData

  const omnImage = searching ? "./mono.gif" : "./omnlogo.gif"

  const handleChainSelected = (value) => {
    setTransactionStatus("")
    setSourceCode("")
    setTrace("")
    setErrorReason("")
    setDecodedInput("")
    setAbi("")
    setCustomErrors("")
    setSearchData("")
    setMessage("")
    setEventTopics("")
    setFunctionSignatures("")
    setContractBytecode("")
    setContractBytecodeHash("")
    setDisplayNav(false)

    setChainId(value)
  }

  const flatten = (obj, prefix = "") => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = prefix ? `${prefix}.${key}` : key

      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (prefix === "") {
          acc[key] = flatten(obj[key])
        } else {
          Object.assign(acc, flatten(obj[key], newKey))
        }
      } else if (typeof obj[key] === "string" && isValidJSON(obj[key])) {
        Object.assign(acc, flatten(JSON.parse(obj[key]), newKey))
      } else {
        acc[newKey] = obj[key]
      }

      return acc
    }, {})
  }

  const isValidJSON = (str) => {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }

  const retrieveTransaction = async () => {
    setSearching(true)
    setSearchType("tx/")
    try {
      const url = `https://wtf-api.omn.us/getTransaction?txn=${searchData}&chainId=${chainId}`

      axios
        .get(url)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error)
            setMessage(response.data.error)
            setSearching(false)
          } else {
            if (response.data.information) {
              console.log(response.data.information)
              setMessage(response.data.information)
            }
            setTransactionStatus(response.data.txnResult)
            //setSourceCode(response.data.sourceCode)
            setSourceCode(flatten(response.data.sourceCode))
            setTrace(response.data.trace)
            setErrorReason(response.data.errorReason)

            const contractInterface = new ethers.Interface(response.data.abi)

            console.log(response.data.trace.input)

            const parsedInput = contractInterface.parseTransaction({
              data: response.data.trace.input,
            })

            console.log("input", parsedInput)

            setDecodedInput(parsedInput)
            setAbi(response.data.abi)
            setCustomErrors(response.data.customErrors)
            setEventTopics(response.data.eventTopics)
            setFunctionSignatures(response.data.functionSignatures)
            setContractBytecode(response.data.contractBytecode)
            setContractBytecodeHash(response.data.contractBytecodeHash)
            setSearching(false)
            setDisplayNav(true)
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          setSearching(false)
          setDisplayNav(false)
        })
    } catch (error) {
      console.error("Error retrieving transaction:", error)
      setSearching(false)
      setDisplayNav(false)
    }
  }

  const retrieveAddress = async () => {
    setSearching(true)
    setSearchType("address/")
    try {
      const url = `https://wtf-api.omn.us/getAddress?address=${searchData}&chainId=${chainId}`

      axios
        .get(url)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error)
            setMessage(response.data.error)
            setSearching(false)
          } else {
            // setSourceCode(response.data.sourceCode)
            setSourceCode(flatten(response.data.sourceCode))
            setAbi(response.data.abi)
            setCustomErrors(response.data.customErrors)
            setEventTopics(response.data.eventTopics)
            setFunctionSignatures(response.data.functionSignatures)
            setContractBytecode(response.data.contractBytecode)
            setContractBytecodeHash(response.data.contractBytecodeHash)
            setSearching(false)
            setDisplayNav(true)
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          setSearching(false)
          setDisplayNav(false)
        })
    } catch (error) {
      console.error("Error retrieving transaction:", error)
      setSearching(false)
      setDisplayNav(false)
    }
  }

  useEffect(() => {
    setTransactionStatus("")
    setSourceCode("")
    setTrace("")
    setErrorReason("")
    setDecodedInput("")
    setAbi("")
    setCustomErrors("")
    setMessage("")
    setEventTopics("")
    setFunctionSignatures("")
    setContractBytecode("")
    setContractBytecodeHash("")
    setDisplayNav(false)

    if (/^0x[a-fA-F0-9]{64}$/.test(searchData)) {
      retrieveTransaction()
    }

    if (/^0x[a-fA-F0-9]{40}$/.test(searchData)) {
      retrieveAddress()
    }
  }, [searchData])

  const handleInputChange = (event) => {
    setSearchData(event.target.value)
  }

  const safeArray = customErrors || []

  // Split array into 3 columns
  const length = safeArray.length
  const half = Math.ceil(length / 2)
  const col1 = safeArray.slice(0, half)
  const col2 = safeArray.slice(half, length)

  const renderErrorTable = (data) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item[1]}</td>
            <td>{item[3]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderTopicTable = (data) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.eventName}</td>
            <td>{item.eventSignature}</td>
            <td>{item.topicHash}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderFunctionTable = (data) => (
    <table>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.functionName}</td>
            <td>{item.functionSignature}</td>
            <td>{item.signatureHash}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    })
  }

  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#2B2B2B",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  }

  return (
    <div>
      <div class="toolbar" id="top">
        <div className="toolbar-left">
          <img src="./applogo.svg" alt="txn.omn.us" />
        </div>

        <div class="toolbar-center"></div>
        <div class="toolbar-right">
          <div id="chain-selector" class="horizontal-container">
            <ChainSelector
              items={chains}
              onItemSelected={handleChainSelected}
            />
            <OmnNav omnImage={omnImage} />
          </div>
        </div>
      </div>
      <div className="search-data-container">
        <div className="input-wrapper">
          <input
            className="search-data-input"
            type="text"
            placeholder="enter txn hash or address"
            value={searchData}
            onChange={handleInputChange}
          />
          <div
            className={searchData ? "underscore-hidden" : "underscore"}
          ></div>
        </div>
      </div>

      <div class="main-content">
        {/* {!searchData && (
          <div class="centered-container">
            <img src="./applogo.svg" alt="txn.omn.us" width="100px" />
          </div>
        )} */}
        {searching && (
          <div style={{ textAlign: "center" }}>
            <h2>searching...</h2>
          </div>
        )}
        {message && (
          <div style={{ textAlign: "center" }}>
            <h2>{message}</h2>
          </div>
        )}

        {transactionStatus && (
          <div id="transaction-outcome" class="section">
            <h2 class="section-header">Transaction Outcome</h2>
            <div class="internal-section-text-only">
              <h3
                style={{
                  color:
                    transactionStatus === "succeeded"
                      ? "#54DA3E"
                      : transactionStatus === "reverted"
                      ? "red"
                      : "inherit",
                }}
              >
                {transactionStatus === "succeeded" && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    aria-hidden="true"
                    style={{ margin: "0 8px" }}
                  />
                )}
                {transactionStatus === "reverted" && (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    aria-hidden="true"
                    style={{ margin: "0 8px" }}
                  />
                )}
                {transactionStatus}
              </h3>
            </div>
          </div>
        )}

        {errorReason && (
          <div id="errorReason" class="section">
            <h2 class="section-header">Revert Message</h2>
            <div class="internal-section-text-only">
              <h3>{errorReason}</h3>
            </div>
          </div>
        )}

        {trace && (
          <div id="trace" class="section">
            <h2 class="section-header">Trace</h2>
            <div class="internal-section">
              <JSONTree data={trace} theme={theme} invertTheme={false} />
            </div>
          </div>
        )}
        {decodedInput && (
          <div id="input" class="section">
            <h2 class="section-header">Decoded Input</h2>
            <div class="internal-section">
              <JSONTree data={decodedInput} theme={theme} invertTheme={false} />
            </div>
          </div>
        )}
        {sourceCode && (
          <div id="source" class="section">
            {Object.keys(sourceCode).map((key, index) => (
              <div key={index}>
                <h2 class="section-header">{key.match(/\w+\.sol/g)}</h2>
                <div class="internal-section">
                  <pre>
                    <CodeBlock
                      text={sourceCode[key]}
                      language="solidity"
                      showLineNumbers={true}
                      wrapLongLines={false}
                      theme={a11yDark} // Use the "atomOneDark" theme
                      customStyle={{ fontSize: "16px" }} // Use inline style
                    />
                    {/* <code>{sourceCode[key]}</code> */}
                  </pre>
                </div>
              </div>
            ))}
          </div>
        )}

        {abi && (
          <div id="abi" class="section">
            <h2 class="section-header">ABI Table</h2>
            <div class="internal-section">
              <JsonTable data={abi} />
            </div>
          </div>
        )}
        {customErrors && customErrors.length > 0 && (
          <div id="errors" class="section">
            <h2 class="section-header">Contract Custom Errors</h2>
            <div class="internal-section">
              <div className="grid-container">
                {renderErrorTable(col1)}
                {renderErrorTable(col2)}
              </div>
            </div>
          </div>
        )}
        {eventTopics && eventTopics.length > 0 && (
          <div id="events" class="section">
            <h2 class="section-header">Event Topics</h2>
            <div class="internal-section">
              <div className="grid-container-single">
                {renderTopicTable(eventTopics)}
              </div>
            </div>
          </div>
        )}

        {functionSignatures && functionSignatures.length > 0 && (
          <div id="functions" class="section">
            <h2 class="section-header">Function Signatures</h2>
            <div class="internal-section">
              <div className="grid-container-single">
                {renderFunctionTable(functionSignatures)}
              </div>
            </div>
          </div>
        )}

        {contractBytecodeHash && (
          <div id="bytecodeHash" class="section">
            <h2 class="section-header">Bytecode Hash</h2>
            <div class="internal-section-text-only">
              <h3>{contractBytecodeHash}</h3>
            </div>
          </div>
        )}

        {contractBytecode && (
          <div id="bytecode" class="section">
            <h2 class="section-header">Bytecode</h2>
            <div class="internal-section-text-only">
              <h3>{contractBytecode}</h3>
            </div>
          </div>
        )}
      </div>
      {displayNav && (
        <div className="horizontal-nav">
          <div className="button-container">
            <button onClick={() => scrollToSection("top")}>top</button>
            {trace && (
              <button onClick={() => scrollToSection("trace")}>trace</button>
            )}
            {decodedInput && (
              <button onClick={() => scrollToSection("input")}>
                decoded input
              </button>
            )}
            <button onClick={() => scrollToSection("source")}>
              source code
            </button>
            <button onClick={() => scrollToSection("abi")}>abi</button>
            {customErrors && customErrors.length > 0 && (
              <button onClick={() => scrollToSection("errors")}>
                custom errors
              </button>
            )}
            {eventTopics && eventTopics.length > 0 && (
              <button onClick={() => scrollToSection("events")}>
                event topics
              </button>
            )}
            {functionSignatures && functionSignatures.length > 0 && (
              <button onClick={() => scrollToSection("functions")}>
                function sigs
              </button>
            )}
            {contractBytecode && (
              <button onClick={() => scrollToSection("bytecodeHash")}>
                bytecode
              </button>
            )}
            <a
              href={scannerLink}
              target="_blank"
              rel="noopener noreferrer"
              className="external-link-button"
            >
              block explorer
            </a>
          </div>
          <div className="footer-text-no-border">
            <span>The Omnus Group (c) {new Date().getFullYear()}</span>
            <span style={{ marginLeft: "20px" }}> honor ante lucrum</span>
          </div>
        </div>
      )}
      {!displayNav && (
        <div className="footer-only">
          <span>The Omnus Group (c) {new Date().getFullYear()}</span>
          <span style={{ marginLeft: "20px" }}> honor ante lucrum</span>
        </div>
      )}
    </div>
  )
}

export default App
